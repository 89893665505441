import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import './CategoryPage.css'
import ThumbnailComponent from "./ThumbnailComponent";
import {data} from "../../assets/data"
import '../../components/Whastapp.css'
import {FormattedMessage} from "react-intl";

const CategoryPage = (props) => {
    const {category} = useParams();
    const [videos, setVideos] = useState([])
    const [selectedVideo, setSelectedVideo] = useState('')
useEffect(()=>{
    // eslint-disable-next-line default-case


    switch (category) {
        case 'Institutional':
            setVideos(data.categories[0].videos)
            break;
        case "Sustainable Development":
            setVideos(data.categories[1].videos)
            break;
        case "Documentaries":
            setVideos(data.categories[2].videos)
            break;
        case "Agriculture":
            setVideos(data.categories[3].videos)
            break;
        case "Testimonials":
            setVideos(data.categories[4].videos)
            break;
        case "Launches":
            setVideos(data.categories[5].videos)
            break;

        case 'Corporate Events':
            setVideos(data.categories[6].videos)
            break;

    }

},[category])
    useEffect(()=>{
        setSelectedVideo(videos[0])
    },[videos])

    useEffect(() => {
        props.sendCategory(category);
        setSelectedVideo(videos[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useLocation()])


    return (
        <div className={'category-page-container'}>
                <span className={'category-title'}><FormattedMessage id={category} defaultMessage={category} /></span>

            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe className={'selected-video'} src={'https://www.youtube.com/embed/' + selectedVideo} loading={"lazy"}
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"/>

            <div className={'thumbnails-grid'}>
                {videos?.map((video) => {
                    return <ThumbnailComponent VideoId={video} setVideo={setSelectedVideo}/>
                })}

            </div>
        </div>
    );
}

export default CategoryPage;