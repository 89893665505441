import React, {useEffect, useState} from "react";
import './ThumbnailComponent.css'
 import getYoutubeTitle from 'get-youtube-title'


const ThumbnailComponent = ({VideoId, setVideo}) => {
    let key = 'AIzaSyDinYsG28RCiFtRuTUw2CltUmWU_A7LflA'

    const [title, setTitle] = useState()
    useEffect(()=>{
        getYoutubeTitle(VideoId, key,function (err, t) {
            setTitle(t)  // 'SLCHLD - EMOTIONS (feat. RIPELY) (prod. by GILLA)'
        })
    },[VideoId])

    return(
        <div className={'thumbnail'}>
            <img className={'thumbnail-image'} src={'https://img.youtube.com/vi/' + VideoId + '/mqdefault.jpg'} onClick={() => setVideo(VideoId)} alt={'Wow'} loading={"lazy"} ></img>
            <p className={'video-title'}>{title}</p>
        </div>


    )
}

export default ThumbnailComponent