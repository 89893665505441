import React, {useEffect} from 'react';
import Slider from "../components/slider/Slider";

const Home = (props) => {

    useEffect(() => {
        props.sendCategory('Home')
        }
        ,[props])

    return (
              <Slider categories={props.categories}/>
      )
    }
;

export default Home;
