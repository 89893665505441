import AwesomeSlider from "react-awesome-slider";
import 'react-awesome-slider/dist/styles.css'
import React from "react";
import './Slider.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import HomeSlide from "./HomeSlide";
import SliderItem from "./SliderItem";
import {data} from "../../assets/data"
const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = ({categories}) => {
    return(

         <AutoplaySlider
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={4000}
        fillParent={true}
        bullets={false}
    >
        {
            categories.map((category, i) => {
                return(
                    <div className={'slider-item-container'}>
                        {i === 0 ? <HomeSlide/> : <SliderItem title = {category} index={i} videoId={data.categories[i-1].videos[0]}/>}
                    </div>
                )}
            )
        }
    </AutoplaySlider>
    )
}

export default Slider
