
import React, {useEffect, useState} from 'react';
import './Slider.css';
import {useNavigate} from "react-router-dom";
import agro from "../../assets/Background/agro2.jpg"
import documentales from "../../assets/Background/documentales.jpg"
import eventos from "../../assets/Background/eventos.jpg"
import lanzamientos from "../../assets/Background/lanzamientos.jpg"
import desarrollo from "../../assets/Background/medioAmbiente.jpg"
import testimoniales from "../../assets/Background/testimoniales.jpg"
import institucioanal from "../../assets/Background/institucional.jpg"
import '../Whastapp.css'
import {FormattedMessage} from "react-intl";

const SliderItem = ({title, index, videoId}) => {
    const[image,setImage] = useState()
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(()=>{
        setImageLoaded(false)
        // eslint-disable-next-line default-case
        switch (title) {
            case 'Institutional':
                loadImage(institucioanal).then(() => setImageLoaded(true) )
                break;
            case 'Sustainable Development':
                loadImage(desarrollo).then(() => setImageLoaded(true) )
                break;
            case 'Agriculture':
                loadImage(agro).then(() => setImageLoaded(true) )
                break;
            case 'Documentaries':
                loadImage(documentales).then(() => setImageLoaded(true) )
                break;
            case 'Testimonials':
                loadImage(testimoniales).then(() => setImageLoaded(true) )
                break;
            case 'Launches':
                loadImage(lanzamientos).then(() => setImageLoaded(true) )
                break;
            case 'Corporate Events':
                loadImage(eventos).then(() => setImageLoaded(true) )
                break;
        }
    },[title])

    const loadImage = (src) => {
        setImage(src)
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
        });
    }

    const navigate = useNavigate()
    return(
            <div>
                {
                    imageLoaded &&
                    <>
                        <img
                            className={'image'}
                            src={image}
                            alt="Wow"
                        />

                    </>

                }
                <div className={index % 2 === 1 ? 'slider-item-content align-content-right' : 'slider-item-content align-content-left'}>
                    <div className={'slider-item-title'}><FormattedMessage id={title} defaultMessage={title} /></div>
                    <div>
                        <a href={`https://www.youtube.com/watch?v=${videoId}`} className={"slider-item-button"} target="_blank" rel="noreferrer">
                            <FormattedMessage id="watchNow" defaultMessage="Watch Now" />
                        </a>
                        <br/>
                        <br/>
                    </div>
                    <button className={'slider-item-button'} onClick={() => navigate('/categoria/' + title)}><FormattedMessage id="showMore" defaultMessage="Show More" /></button>
                </div>

            </div>
    )
}

export default SliderItem;
