import React, {useEffect} from 'react';
import emailjs from 'emailjs-com';
import "./ContactForm.css"
import '../../components/Whastapp.css'
import {FormattedMessage} from "react-intl";
const ContactForm = (props) => {

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4yvb2qy', 'template_5nkbdr4', e.target, '-AZk7MS3pBk5YmhpL')
            .then((result) => {
            }, (error) => {
            });
            e.target.reset();
        // eslint-disable-next-line no-undef
        gtag_report_conversion('/gracias-por-contactarnos');

    }

    useEffect(() => {
            props.sendCategory('contacto')
        }
        ,[props])

    return (
            <div className={'container'}>
                <div className={'form-title'}> <FormattedMessage id="contact" defaultMessage="Contact" /></div>
                <form className="form-container" onSubmit={sendEmail}>
                        <div className="input-form-container">
                            <div className="input-form">
                                <label><FormattedMessage id="fullName" defaultMessage="Full Name" /></label>
                                <br/>
                                <input type="text" name="name"/>
                            </div>

                            <div className="input-form">
                                <label><FormattedMessage id="email" defaultMessage="Email" /></label>
                                <br/>
                                <input type="email" name="email"/>
                            </div>

                            <div className="input-form">
                                <label><FormattedMessage id="subject" defaultMessage="Subject" /></label>
                                <br/>
                                <input type="text" name="subject"/>
                            </div>
                        </div>

                        <div className="textarea-form">
                            <label><FormattedMessage id="message" defaultMessage="Message" /></label>
                            <br/>
                            <br/>
                            <textarea className={'textarea'} name="message" rows='10' cols='30'/>
                        </div>

                        <div className="btn-container">
                            <button type="submit"
                                    className="btn-contact">
                                <FormattedMessage id="sendEmail" defaultMessage="Send Email" />
                            </button>
                        </div>
                    </form>
                <div className="info-container"/>
            </div>
    );
}

export default ContactForm;
