import './App.css';
import {Route, Routes} from "react-router-dom";
import React, {useEffect, useState} from "react";
import esMessages from "./locales/es.json";
import enMessages from "./locales/en.json";
import Main from "./main/Main";
import {IntlProvider} from "react-intl";



function App() {
    const [token, setToken] = useState();
    useEffect(() => {
        const tokenStorage = localStorage.getItem('TOKEN');
        if (token) {
            setToken(tokenStorage);
        }
    },[]);
    const userLanguage = navigator.language.split('-')[0];
    console.log(userLanguage);
  return (
    <div className="App">
        <IntlProvider locale={userLanguage} messages={
            userLanguage === 'es' ? esMessages : enMessages
        }>
          <Routes>
              <Route exact path="*" element={<Main/>}/>
              {/*<Route path="admin" element={<Admin/>}>
                  <Route path="" element={<Login/>}/>
                  <Route path="home" element={<AdminHome/>}/>
              </Route>*/}
          </Routes>
        </IntlProvider>
    </div>
  );
}

export default App;
