import "./AboutUs.css"
import {data} from "../../assets/data"
import WebFont from 'webfontloader';
import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import'../../components/Whastapp.css'
import LogosDisplay from "./LogosDisplay";
import {FormattedMessage} from "react-intl";
export const AboutUs = (props) => {
    useEffect(() => {
        WebFont.load({
            google: {
                families: [ 'Passion One']
            }
        });
    }, []);

    useEffect(() => {
            props.sendCategory('aboutus')
        }
        ,)
  return(
      <div className={'about-screen-container'}>
      <div className="about-container">
          <div className="text-container">
              <span className={'about-us-title'}> <FormattedMessage id="aboutUs" defaultMessage="About Us" /> </span>
              <div className="about-paragraph">
                  <FormattedMessage id="aboutUsMessage" defaultMessage="" />
              </div>
          </div>
          <LogosDisplay/>
      </div>

    </div>
  )
}