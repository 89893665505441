import React, {useState} from 'react';
import { Route, Routes} from "react-router-dom";
import CategoryPage from "./screens/CategoryPage/CategoryPage";
import Home from "./screens/Home";
import NavBar from "./components/navBar/NavBar";
import ContactForm from "./screens/ContactForm/ContactForm";
import {AboutUs} from "./screens/AboutUs/AboutUs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Main = () => {

    const categories = ['Home', 'Institutional', 'Sustainable Development', 'Documentaries', 'Agriculture', 'Testimonials', 'Launches', 'Corporate Events'];
    const [category, setCategory] = useState('Home');

    const childCat = (data) => {
        setCategory(data);
    }

    return (
        <>
            <NavBar categories={categories} active={category} className={category==='Home'?'active':''}/>
            <Routes>
                <Route exact path={'/'} element={<Home categories={categories} sendCategory={childCat}/>}/>
                <Route path={"/categoria/:category"} element={<CategoryPage categories={categories} sendCategory={childCat}/>}/>
                <Route path={"contacto"} element={<ContactForm categories={categories} sendCategory={childCat}/>}/>
                <Route path={"gracias-por-contactarnos"} element={<ContactForm categories={categories} sendCategory={childCat}/>}/>
                <Route path={"aboutus"} element={<AboutUs categories={categories} sendCategory={childCat}/>}/>
            </Routes>
            <div>
                <a href="https://wa.me/5491144012905" className={"float"} target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faWhatsapp} size={"2x"}/></a>
            </div>
        </>

    );
}

export default Main;