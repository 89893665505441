import React, {useState} from 'react';
import './AboutUs.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const LogosDisplay = (props) => {
    const logosAmount = window.innerWidth < 830 ? 3 : 5;
    const logos = [];
    const [offset, setOffset] = React.useState(0);
    const logoFiles = require.context('../../assets/logos', false, /\.png$/);
    const [slideIn, setSlideIn] = useState('');
    const [key, setKey] = useState(0);
   const moveCarroussel = (direction) => {
       setKey(key+1);
       if (direction === 'back') {
           setSlideIn('back')
           if (offset-logosAmount <= 0) setOffset(0)
           else setOffset(offset - logosAmount);
       } else if (direction === 'forward') {
           setSlideIn('forward')
           if (offset+logosAmount >= logos.length-logosAmount) setOffset(logos.length-logosAmount)
           else setOffset(offset + logosAmount);
       }
   }
    logoFiles.keys().forEach( (logoPath)=> {
        logos.push(
            <div className={'logo-container'}>
                <img src={require(`../../assets/logos${logoPath.slice(1, logoPath.length)}`)} className={'logo-item'} alt={'logo'}/>
            </div>
        );
    });
  return(
      <div  className={'logos-display'}>
          <div className={`arrow-container ${offset + logosAmount >= logos.length ? 'disabled' : ''}`}
               onClick={()=>moveCarroussel('back')
               }
          >
              <FontAwesomeIcon icon={faChevronLeft} className={`arrow  ${offset === 0 ? 'disabled' : ''}`} />

          </div>
          <div key={key} className={`logos-carousel slide-in-${slideIn}`}  >
              {logos.slice(offset,offset+logosAmount)}
          </div>
          <div className={`arrow-container ${offset + logosAmount >= logos.length ? 'disabled' : ''}`}
               onClick={()=>moveCarroussel('forward')}
          >
              <FontAwesomeIcon icon={faChevronRight} className={`arrow  ${offset + logosAmount >= logos.length ? 'disabled' : ''}`}/>
          </div>
      </div>
  )
}
export default LogosDisplay;