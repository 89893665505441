export const data = {
    aboutus: '             NIMAGEN MEDIA es una productora con mas de 25 años de experiencia en el mercado especializada en la realización de contenidos audiovisuales y su posterior difusión en las distintas redes sociales.\n' +
        '\n' +
        '                  Nuestra actividad abarca desde la realización de documentales, comerciales y videos corporativos hasta la cobertura de eventos especiales.\n' +
        '\n' +
        '                  Para cada idea existe una herramienta. Por eso contamos con equipamiento de vanguardia. Drones de distintas características, cámaras ultra slow motion y 360 grados, gruas, gimbals, cabezales programables y sliders robotizados componen parte de nuestra dotación de trabajo.\n' +
        '\n' +
        '                  Nuestra filosofía se basa en una fórmula de trabajo que aúna creatividad y funcionalidad. Para lograrlo mantenemos una estrecha y contínua comunicación con el cliente, construyendo así un producto de alta calidad adaptado a sus necesidades operativas y comerciales.'
    ,categories: [
        {
            name: "Institucionales",
            image: '',
            videos: ['SeYCImFNtLU','t88IIL8GPHg', 'NEoEHYhPgeo','NcetxR6t7lw', 'nj0CU7oPJQI', 'lcQqWaoYtZA','YqQPcJphFyM', '93dR-Bak83A'],
        },
        {
            name: "Desarrollo Sustentable",
            image: '',
            videos: ['X07EELs7pI8','-3Y3kNO4PD8','_pKHpkB-7fU','eAqSRWh0Mss', '3b9owgQI1xg','9HICQml7Prk','DIjKamclDYA','jmyp97tHgCo','X3cp0ElbPSU']

        },
        {
            name: "Documentales",
            image: '',
            videos: ['GQxZBUGfeSs','fLptoJVysjk','2qWKkh6w_y4','KcQx2Di8DAc','RYW6FT3CBb4','rxYPFspXJv0','oZE3cuUQN7s','0uZvRThSNj0','nBrQYtabdck','1ZWuMavnVhU','OyqKlzoR-mA'],
        },
        {
            name: "Agro",
            image: '',
            videos: ['JLd5bZkjdvM','ShvLhlx12g0','7mjdbQQdcT0','OmX9bevfNMI','XVAjMe1Rs1k','cnDy30PFgjo']

        },
        {
            name: "Testimoniales",
            image: '',
            videos: ['RHND4JM_NGQ','l0cAqKTVE4A','7iY_EHLK7Lo','S0Rlxf1TOiY','ert27F2oW2o','KbuqG9eVq9c']

        },
        {
            name: "Lanzamientos",
            image: '',
            videos: ['LnC746fuLHI','I5NnliFwAHI','N2c-NoJe73A','aGph9UPAyMI','Ow6kfvfV3ns','UpCsWJPBZgQ','J6UUnM2JbC0','opevgKVvWCs','AGEIOQr6tds','vvBLHmJEL5I','03jd2-qcM2o','tqrCyaN9oes','J8NFmxsVAYg','hz2iKfYEoto','MOlZkk1Ag08','M3M0BQWwO3A','dPxpfJsFB8Y','VsWIz4AKJZQ']

        },
        {
            name: "Eventos Corporativos",
            image: '',
            videos: ['SNKhiM1XNt4','wozReTPxPVk','Sp5lTz3oOTw','2PJFbGs1Vpg','isz1Utkhieg','EuBn-DSbpjM','NOqMReCMddU','0PBMfOSCF2Q','Nf-j40JfS0U','gskvpC1D5Rw','WCJBKApjvNM','CgaK9QnlHEA','v3T-rhWRj_M','gYWqKFUCAl0','Y4ZrMCpyQ6U','ea-mN58zFEQ','-CvGRX7XVtk','BEGLrGRNCcw','j40XprVMNOY','K_TM0zj_Tyo','BCfw1Ll3IwY','7xn01olzEPI','uvnhACWTeqI']
            ,
        },
    ],
}