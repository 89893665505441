import {useNavigate} from "react-router-dom";
import './NavBar.css'
import {useState} from "react";
import logo from "../../assets/nimagen_logo.png"
import {FormattedMessage} from "react-intl";
const NavBar = (props) => {
    const { innerWidth, innerHeight } = window;
    const navigate = useNavigate()
    const [activeMenu, setActiveMenu] = useState(false);

    const goTo = (category) => {
        setActiveMenu(false);
        if (category === 'Home'){
            navigate('/');
        }else{
            navigate('/categoria/' + category);
        }
    }
    const menuActive = () => {
        setActiveMenu(!activeMenu);
    }
    return(
        <div>
            {
                innerWidth > 1000 ?
                    <div className={`nav-container ${props.className} ${props.active}`}>
                        <div className={`nv-logo-container`}>
                            <img src={logo} className={`logo ${'Home' === props.active ? 'nav-item-active' : 'nav-item'}`} onClick={ ()=>goTo('Home')}></img>
                        </div>
                        {props.categories.filter(c=> c !== 'Home').map((category,i) => {
                            return <span
                                key={i}
                                className={category === props.active ? 'nav-item-active' : 'nav-item'}
                                onClick={ () => goTo(category)}>
                                <FormattedMessage id={category} defaultMessage={category} />
                            </span>
                        })}
                        <span key={'contacto'} className={'contacto' === props.active ? 'nav-item-active' : 'nav-item'} onClick={ () => navigate("contacto")}><FormattedMessage id="contact" defaultMessage="Contact" /></span>
                        <span key={'aboutus'} className={'aboutus' === props.active ? 'nav-item-active' : 'nav-item'} onClick={ () => navigate("aboutus")}><FormattedMessage id="aboutUs" defaultMessage="About Us" /></span>
                    </div> :
                    <div>
                        <div className={`nav-hamburger ${props.className}`}>
                            <button className={!activeMenu ? "hamburger" : "hamburger hamburger-active"} type="button" onClick={menuActive}>
                                <span className="line"/>
                                <span className="line"/>
                                <span className="line"/>
                            </button>
                        </div>
                        <div className={activeMenu ? "side-navbar side-navbar-active" : "side-navbar"}>
                            {props.categories.map((category,i) => {
                                return <span key={i} className={category === props.active ? 'sidenav-item-active' : 'sidenav-item'} onClick={ () => goTo(category)}>
                                    {category}
                                </span>
                            })}
                            <span className={'contacto' === props.active ? 'sidenav-item-active' : 'sidenav-item'} onClick={ () => navigate("contacto")}>
                                <FormattedMessage id="contact" defaultMessage="Contact" />
                            </span>
                            <span className={'aboutus' === props.active ? 'sidenav-item-active' : 'sidenav-item'} onClick={ () => navigate("aboutus")}>
                                <FormattedMessage id="aboutUs" defaultMessage="About Us" />
                            </span>
                        </div>
                        <a href="javascript:void(0);" className="icon" onClick="myFunction()">
                        </a>

                    </div>
            }
        </div>

    )
}

export default NavBar
