import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import  image from "../../assets/Background/IMG_20190425_193345.jpg"
import imagePhone from "../../assets/Phone/Home-Phone.jpg"
import { faFacebook, faYoutube, faInstagram,faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import './Slider.css';
import'../Whastapp.css'
import {FormattedMessage} from "react-intl";

const HomeSlide = () => {
    return (
            <div>
                <img
                    className={'image'}
                    alt="Wow"
                    src={image}
                />
                <img
                    className={'phone-image'}
                    alt="Wow"
                    src={imagePhone}
                />
                <h1><FormattedMessage id="NIMAGEN" defaultMessage="NIMAGEN" /></h1>
                <div className= { 'subtitle'} >
                    <h6><FormattedMessage id="AudiovisualProductions" defaultMessage="Audiovisual Productions" /></h6>
                </div>

                <div className={"icons-container"}>
                    <div >
                        <a href="https://www.facebook.com/Nimagen-Media-2329240910621099" className={"icon-btn fb"} target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faFacebook} size={"2x"}/></a>
                    </div>
                    <div >
                        <a href="https://www.youtube.com/c/NIMAGENMedia" className={"icon-btn yt"} target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faYoutube} size={"2x"}/></a>

                    </div>
                    <div>
                        <a href="https://www.instagram.com/nimagenmedia/" className={"icon-btn ig"} target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faInstagram} size={"2x"}/></a>
                    </div>

                </div >
            </div>
    );
}

export default HomeSlide;